// eslint-disable-next-line import/prefer-default-export
export const defaultTheme = {
  activeColor: '#042C89',
  activeTextColor: '#ffffff',
  backgroundColor: '#ffffff',
  mainTextColor: '#000000',
  primaryColor: '#6F7271',
  primaryTextColor: '#ffffff',
  secondaryColor: '#F2F2F3',
  secondaryTextColor: '#616165',
  downloadButtonColor: '#747d8c',
  emailButtonColor: '#747d8c',
  webShareButtonColor: '#747d8c',
  dangerColor: '#d93636',
  successColor: '#1e854c',
  shimmerBaseColor: '#e6e6e6',
  shimmerAccentColor: '#cccccc',
};
