import { h } from 'preact';
import classNames from 'classnames';

import style from './style.scss';

const Loader = (props) => {
  const {
    message = '',
    fullScreen,
    secondary,
    disabled,
    alternative,
    shimmer,
    className,
  } = props;

  const loaderColor = () => {
    if (secondary) {
      return style.secondary;
    }

    if (disabled) {
      return style.disabled;
    }

    if (alternative) {
      return style.alternative;
    }

    return style.primary;
  };

  return (
    <div
      className={classNames(
        className,
        {
          [style.fullScreen]: fullScreen,
          [style.loaderContainer]: !fullScreen,
          [style.shimmerContainer]: shimmer,
        },
      )}
    >
      {shimmer ? (
        <div className={style.shimmer}>
          <div className={style.wrapper}>
            <div className={classNames(style.imageCard, style.animate)} />
          </div>
        </div>
      ) : (
        <svg className={loaderColor()} viewBox="0 0 512 512">
          <path d="M280 24c0-13.3-10.7-24-24-24s-24 10.7-24 24v80c0 13.3 10.7 24 24 24s24-10.7 24-24V24zm0 384c0-13.3-10.7-24-24-24s-24 10.7-24 24v80c0 13.3 10.7 24 24 24s24-10.7 24-24V408zM0 256c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H24c-13.3 0-24 10.7-24 24zm408-24c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H408zM437 75c-9.4-9.4-24.6-9.4-33.9 0l-56.6 56.6c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L437 108.9c9.4-9.4 9.4-24.6 0-33.9zM165.5 380.4c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L75 403.1c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l56.6-56.6zM75 75c-9.4 9.4-9.4 24.6 0 33.9l56.6 56.6c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L108.9 75c-9.4-9.4-24.6-9.4-33.9 0zM380.5 346.5c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L403.1 437c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-56.6-56.6z" />
        </svg>
      )}
      {
        message && (
          <span className={style.loaderMessage}>
            {message}
          </span>
        )
      }
    </div>
  );
};

export default Loader;
